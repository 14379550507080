import React from 'react'
import Layout from '../components/layout'
import PhoneInput from 'react-phone-input-2'
import TZSelect from '../components/timezoneSelect'
import 'react-phone-input-2/lib/style.css'

export default class MuchAdoSignUp extends React.Component {
    constructor(props){
        super(props)

        this.state= {
            email: "",
            phone: "",
            selectedTZ: {},
            isSubmitting: false,
            phoneWarning: false,
            tzWarning: false
        }
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
      }
    submitForm = (e) => {
        e.preventDefault()
        if (this.state.phone == "") {
            this.setState({
                phoneWarning: true     
            }) }
        else if (this.state.selectedTZ.value === undefined) {
            if (this.state.phone !== "") {
                this.setState({
                    phoneWarning: false
                })
            }
            console.log("empty timezone")
            this.setState({
                tzWarning: true     
            })
        } else {
            console.log("both")
            this.setState({
                isSubmitting: true
            })
    
            console.log(this.state.phone)
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    phone: "+" + this.state.phone,
                    tz: this.state.selectedTZ.offset
                })
            };
            fetch('https://much-ado-over-texting-backend.com/signup', requestOptions)
            // fetch('https://ec2-54-210-130-12.compute-1.amazonaws.com/signup', requestOptions)
                // .then(response => response.json())
                .then(res => {
                    this.setState({
                        isSubmitting: false
                    })
                    window.alert("Good job, you submitted succesfully! Please look for a text message from us.")
                    setTimeout(() => {  
                        this.setState({
                            email: "",
                            phone: "",
                            tz: 0,
                            isSubmitting: false,
                            phoneWarning: false,
                            tzWarning: false
                        })
                     }, 3000);
                });
        }
    }
    getQueryVariable = (variable) => {
        var query = window.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1]);
            }
        }
        console.log('Query variable %s not found', variable);
    }

    setSelectedTimezone = (e) => {
        this.setState({
            selectedTZ: e
        })
    }

    render(){
        return (
            <Layout>
                <div className="much-ado-body-wrapper">
                    <div className="much-ado-form-wrapper">
                        <h2>We're so excited to have you!</h2> 
                        <h2>If we can just get your phone number, we can begin the journey!</h2>
                        <form class="contact-form-body">
                            <div className="much-ado-inputs-wrapper">
                                {/* <input 
                                onChange={this.handleChange}
                                value={this.state.email}
                                className="contact-input half-to-full contact-less-padding" 
                                type="email" 
                                name="email" 
                                placeholder="Your email" /> */}
                                <p style={{textAlign:"left", marginBottom: 6}}>What is your cell phone number?</p>
                                <PhoneInput
                                country={'us'}
                                containerClass="phone-container"
                                inputClass="phone-input"
                                countryCodeEditable={false}
                                value={this.state.phone}
                                onlyCountries={['us']}
                                onChange={phone => this.setState({ phone })}
                                />
                                {
                                   this.state.phoneWarning ? (
                                        <p className="warning-label">Remember to enter your cell phone number</p>
                                   ) : (
                                       null
                                   )
                               } 
                                <p style={{textAlign:"left",marginBottom: 6}}>What timezone are you in?</p>
                                <TZSelect 
                                    selectedTimezone={this.state.selectedTZ} 
                                    setSelectedTimezone={this.setSelectedTimezone}
                                    />
                                    
                                {/* <select 
                                className="tz-select phone-container" 
                                id="tz" name="tz" 
                                value={this.state.tz} 
                                onChange={this.handleChange}
                                >
                                    <option value={""}></option>                                    
                                    <option value={"0"}>Eastern (EST)</option>
                                    <option value={"1"}>Central (CST)</option>
                                    <option value={"2"}>Mountain (MST)</option>
                                    <option value={"3"}>Pacific (PST)</option>
                                </select> */}
                               {
                                   this.state.tzWarning ? (
                                        <p className="warning-label">Remember to choose your time zone</p>
                                   ) : (
                                       null
                                   )
                               } 
                            </div>
                            {this.state.isSubmitting ? (
                                <p>Submitting....</p>
                            ) : (
                                <button type="submit" onClick={this.submitForm} className="button--fullWidth blackButton--border">Sign up</button>
                                )
                            }
                        </form>
                    </div>
                </div>
            </Layout>
        )
    }
}
